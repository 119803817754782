import { getCancelRate, getCancelType } from "@/api/cancel";

const actions = {
  getCancelRate({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      getCancelRate(form, state.token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCancelType({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      getCancelType(form, state.token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  actions,
  namespaced: true,
};
