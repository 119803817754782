import { pddCallBack } from "@/api/auth";

const actions = {
    pddCallBack({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            pddCallBack(form, state.token)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

export default {
    actions,
    namespaced: true,
};
