import request from "@/utils/request";

// 查询分组列表
export function listGroup(data) {
    return request({
        url: '/authorizeGroupRel/listGroup',
        method: 'post',
        data
    })
}
//归纳至分组
export function addToGroup(data) {
    return request({
        url: '/authorizeGroupRel/addToGroup',
        method: 'post',
        data
    })
}
//删除分组
export function deleteGroup(data) {
    return request({
        url: '/authorizeGroupRel/deleteGroup',
        method: 'post',
        data
    })
}
//新增或修改分组
export function saveAuthorGroup(data) {
    return request({
        url: '/authorizeGroupRel/saveAuthorGroup',
        method: 'post',
        data
    })
}
