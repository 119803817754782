import { getSecurityToken, uploadImageToOSS, initCategory, uploadFileToOSS } from '@/api/common'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    //获取oss签名
    getSecurityToken({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getSecurityToken(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 文件上传oss
    uploadFileToOSS({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            uploadFileToOSS(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 文件上传oss
    uploadImageToOSS({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            uploadImageToOSS(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 筛选条件初始化-分类
    initCategory({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            initCategory(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

}

export default {
    namespaced: true,
    state,
    actions
}
