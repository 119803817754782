import request from "@/utils/request";

// 添加标签
export function saveNote(data) {
    return request({
        url: '/customNote/saveNote',
        method: 'post',
        data
    })
}

//展示标签下的商品及子标签
export function listAllNotes(data) {
    return request({
        url: '/customNote/listAllNotes',
        method: 'post',
        data
    })
}
//移动商品
export function addAndMoveGoods(data) {
    return request({
        url: '/customNote/addAndMoveGoods',
        method: 'post',
        data
    })
}
//查询标签下的商品
export function getNotesAndGoods(data) {
    return request({
        url: '/customNote/getNotesAndGoods',
        method: 'post',
        data
    })
}
// 删除标签
export function deleteNote(data) {
    return request({
        url: '/customNote/deleteNote',
        method: 'post',
        data
    })
}