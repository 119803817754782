import Vue from "vue";

import Viewer from "v-viewer";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import common from "./assets/common";
import * as echarts from "echarts";
import moment from "moment"; //时间
moment.locale("zh-cn"); //设置语言 或 moment.lang('zh-cn');
Vue.prototype.moment = moment; //赋值使用

Vue.use(ElementUI);
Vue.prototype.common = common;
Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
    console.error("Error:", err, info);
};

import * as Base64 from "js-base64";
Vue.use(Base64);

import "viewerjs/dist/viewer.css"; //引入css样式
Vue.use(Viewer); //全局使用
// 挂载
Vue.prototype.$echarts = echarts;

// main.js
import jsCookie from "js-cookie";
Vue.prototype.$cookie = jsCookie; // 在页面里可直接用 this.$cookie 调用

//decode
Vue.filter("decode", function(str) {
    var result = str;
    if(arguments.length > 1) {
        if(arguments.length == 2) {
            result = args;
        } else {
            for(var i = 1; i < arguments.length; i = i + 2) {
                if(arguments[i] != undefined) {
                    if(arguments[i + 1] != undefined) {
                        if(result == arguments[i]) {
                            result = arguments[i + 1];
                            break;
                        }
                    } else {
                        result = arguments[i];
                    }
                }
            }
        }
    }
    return result;
});

// 阿里云处理图片缩放问题
Vue.filter("image", function(value, ...args) {
    if(typeof value == "string") {
        if(value.includes("oss-cn-hangzhou") || value.includes("hishowstatic")) {
            let str = "";
            if(args.length == 2) {
                str = "?x-oss-process=image/resize,";
                let width = "w_" + args[0] + ",";
                let height = "h_" + args[1] + "";
                str = str + width + height;
            }
            return value + str;
        }
    }
    return value;
});

Vue.filter("formatPercent", function(value) {
    if(value === null) {
        return "--";
    }
    return (value * 100).toFixed(2) + "%";
});
//金额千分
Vue.filter("formatMoney", function(money) {
    let result = money;
    if(money) {
        var parts = money.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if(parts.length > 1) {
            result = parts.join(".");
        } else {
            result = parts[0];
        }
    }
    return result;
});
//隐藏手机号中间四位
Vue.filter("hideShop", function(value) {
    if(value) {
        let result = value.toString();
        let first = result.slice(0, 1);
        let end = result.slice(-1);
        return first + "****" + end;
    } else {
        return value;
    }
});
Vue.filter('timeFormat', function(timestamp) {
    if(!timestamp) return '';
    // 将秒级时间戳转换为毫秒级时间戳
    const date = new Date(timestamp * 1000);
    // 格式化日期，例如：YYYY-MM-DD HH:mm:ss
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
});
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
