import { updateAuthorityRecord,shieldSupplier, listAuthorRecords, addShieldSupplier, agreeAuthority, applyAuthority, deleteAuthority, listAuthorSuppliersImages } from '@/api/authorizeRel'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    //商家端 授权申请 删除、取消授权申请
    updateAuthorityRecord({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            updateAuthorityRecord(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //屏蔽厂家
    shieldSupplier({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            shieldSupplier(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //展示厂家图片
    listAuthorSuppliersImages({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            listAuthorSuppliersImages(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //删除 授权厂家&商家
    deleteAuthority({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            deleteAuthority(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //申请授权
    applyAuthority({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            applyAuthority(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 授权通过&取消&忽略
    agreeAuthority({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            agreeAuthority(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 增加屏蔽厂家
    addShieldSupplier({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            addShieldSupplier(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 查询分组列表
    listAuthorRecords({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            listAuthorRecords(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions
}
