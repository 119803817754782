import request from "@/utils/request";

// 获取分组列表
export function getMenu(data) {
    return request({
        url: '/menuInfo/getMenu',
        method: 'post',
        data
    })
}
