import request from "@/utils/request";

// 列表查询
export function listCollectRecords(data) {
    return request({
        url: '/supShopCollect/listCollectRecords',
        method: 'post',
        data
    })
}
// 收藏厂商/商家
export function collectSupplier(data) {
    return request({
        url: '/supShopCollect/collectSupplier',
        method: 'post',
        data
    })
}