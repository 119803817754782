import { getBaseInfo, IsSupplierAuthored, saveRemarkInfo } from "@/api/remark";
const actions = {
  getBaseInfo({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      getBaseInfo(form, state.token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  IsSupplierAuthored({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      IsSupplierAuthored(form, state.token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveRemarkInfo({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      saveRemarkInfo(form, state.token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
