import request from "@/utils/request";

export function queryGoodsScore(data) {
  return request({
    url: "/goodsScore/queryGoodsScore",
    method: "post",
    data,
  });
}

export function saveGoodsScore(data) {
  return request({
    url: "/goodsScore/saveGoodsScore",
    method: "post",
    data,
  });
}

export function deleteScore(data) {
  return request({
    url: "/goodsScore/deleteScore",
    method: "post",
    data,
  });
}
