import { getMenu } from '@/api/menuInfo'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    // 加载财务列表
    getMenu({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getMenu(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions
}
