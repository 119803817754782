import request from "@/utils/request";

export function getStorageList(data) {
  return request({
    url: "/goodsRel/getStorageList",
    method: "post",
    data,
  });
}

export function saveStorage(data) {
  return request({
    url: "/goodsRel/saveStorage",
    method: "post",
    data,
  });
}

export function relateGoods(data) {
  return request({
    url: "/goodsRel/relateGoods",
    method: "post",
    data,
  });
}
