import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context("./modules", false, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    // set './app.js' => 'app'
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
    const value = modulesFiles(modulePath);
    modules[moduleName] = value.default;
    return modules;
}, {});

const store = new Vuex.Store({
    modules,
    state: {
        processShow: false,
        unUpload: 0,
        uploaded: 0,
        pati: '',
        pageCode: '',
    },
    getters: {
        getProcessShow: state => state.processShow,
        getUnUpload: state => state.unUpload,
        getUploaded: state => state.uploaded,
        getPati: state => state.pati,
        getPageCode: state => state.pageCode,
    },
    mutations: {
        setPati(state, source) {
            state.pati = source
        },
        setPageCode(state, source) {
            state.pageCode = source
        },
        setProcessShow(state, source) {
            state.processShow = source
        },
        setUnUpload(state, source) {
            state.unUpload = source
        },
        setUploaded(state, source) {
            state.uploaded = source
        },
    },
});

export default store;
