import { collectSupplier,listCollectRecords } from '@/api/supShopCollect'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    // 列表查询
    listCollectRecords({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            listCollectRecords(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //收藏厂家/商家
    collectSupplier({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            collectSupplier(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions
}
