import { saveNote, listAllNotes, addAndMoveGoods, getNotesAndGoods, deleteNote } from '@/api/customNote'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    // 添加标签
    saveNote({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            saveNote(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 展示标签下的内容
    listAllNotes({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            listAllNotes(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 移动商品
    addAndMoveGoods({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            addAndMoveGoods(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 删除标签
    getNotesAndGoods({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getNotesAndGoods(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 删除标签
    deleteNote({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            deleteNote(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

}

export default {
    namespaced: true,
    state,
    actions
}
