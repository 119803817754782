import { addToImageRepository,getImageList, uploadImage, getDetailInfo,updateImageType,insertDownloadHis,getPushDetailInfo } from '@/api/supplierImage'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    
    addToImageRepository({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            addToImageRepository(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getPushDetailInfo({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getPushDetailInfo(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    insertDownloadHis({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            insertDownloadHis(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //改变图片状态
    updateImageType({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            updateImageType(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //获取图片详情信息
    getDetailInfo({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getDetailInfo(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //获取图片列表信息
    getImageList({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getImageList(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //添加商品
    uploadImage({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            uploadImage(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions
}
