import { login, register, getUserInfo, logout,sendVerifyCode,bindRole } from '@/api/account'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    //绑定用户角色
    bindRole({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            bindRole(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 获取验证码
    sendVerifyCode({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            sendVerifyCode(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    logout({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            logout(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getUserInfo({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getUserInfo(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 登录
    login({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            login(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 注册
    register({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            register(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

}

export default {
    namespaced: true,
    state,
    actions
}
