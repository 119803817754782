import request from "@/utils/request";

// 列表查询
export function listCollectProducts(data) {
    return request({
        url: '/productCollect/listCollectProducts',
        method: 'post',
        data
    })
}
// 收藏厂商/商家
export function collectProduct(data) {
    return request({
        url: '/productCollect/collectProduct',
        method: 'post',
        data
    })
}