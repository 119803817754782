import { getIsvPageCode, commitDraft, getDraftDetail, saveDraftWhenCommit, saveDraftWhenUpdate, pageCommits, dropDraft, updateProduct, singleUpdateStorage, singleUpdatePrice, getDetail, uploadOssImage, createMaterial, listMaterial, deleteMaterial, productDeliveryLog, listProducts, batchAddPddProduct, onOffSale, batchUpdateTitle, batchUpdateStorage, batchUpdatePrice, getCats, getPddAuthStatus, addPddProduct } from '@/api/delivery'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    //获取签名
    getIsvPageCode({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getIsvPageCode(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //保存对草稿的修改
    commitDraft({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            commitDraft(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //获取草稿详情
    getDraftDetail({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getDraftDetail(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //发布的时候保存草稿
    saveDraftWhenCommit({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            saveDraftWhenCommit(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //4.保存草稿
    saveDraftWhenUpdate({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            saveDraftWhenUpdate(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //4.草稿列表
    pageCommits({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            pageCommits(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //删除草稿
    dropDraft({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            dropDraft(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    //5.商品编辑
    updateProduct({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            updateProduct(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //6.单商品库存修改
    singleUpdateStorage({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            singleUpdateStorage(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //7.单商品价格修改
    singleUpdatePrice({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            singleUpdatePrice(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //拼多多获取商品详情
    getDetail({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getDetail(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //拼多多素材编辑-图片上传
    uploadOssImage({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            uploadOssImage(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //拼多多素材编辑-图片上传
    createMaterial({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            createMaterial(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    //拼多多素材编辑-列表查询
    listMaterial({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            listMaterial(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //拼多多素材编辑-删除
    deleteMaterial({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            deleteMaterial(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //获取拼多多批货记录
    productDeliveryLog({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            productDeliveryLog(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 获取拼多多商品
    listProducts({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            listProducts(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    // 拼多多商品批量发布
    batchAddPddProduct({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            batchAddPddProduct(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 拼多多商品上下架
    onOffSale({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            onOffSale(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 拼多多商品的标题批量编辑
    batchUpdateTitle({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            batchUpdateTitle(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 拼多多商品的库存修改
    batchUpdateStorage({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            batchUpdateStorage(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 拼多多商品的价格批量编辑
    batchUpdatePrice({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            batchUpdatePrice(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 拼多多商品发布
    addPddProduct({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            addPddProduct(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 拼多多是否授权
    getPddAuthStatus({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getPddAuthStatus(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 获取品类
    getCats({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getCats(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions
}
