import request from "@/utils/request";

// 列表查询
export function getCats(data) {
    return request({
        url: '/delivery/pdd/getCats',
        method: 'post',
        data
    })
}

// 拼多多商品发布
export function addPddProduct(data) {
    return request({
        url: '/delivery/pdd/addPddProduct',
        method: 'post',
        data
    })
}

//获取拼多多授权情况
export function getPddAuthStatus(data) {
    return request({
        url: '/delivery/pdd/authStatus',
        method: 'post',
        data
    })
}

//拼多多商品的库存修改
export function batchUpdateStorage(data) {
    return request({
        url: '/delivery/pdd/batchUpdateStorage',
        method: 'post',
        data
    })
}
//拼多多商品的价格批量编辑
export function batchUpdatePrice(data) {
    return request({
        url: '/delivery/pdd/batchUpdatePrice',
        method: 'post',
        data
    })
}
//拼多多商品的标题批量编辑
export function batchUpdateTitle(data) {
    return request({
        url: '/delivery/pdd/batchUpdateTitle',
        method: 'post',
        data
    })
}
//拼多多商品上下架
export function onOffSale(data) {
    return request({
        url: '/delivery/pdd/onOffSale',
        method: 'post',
        data
    })
}
//拼多多商品批量发布
export function batchAddPddProduct(data) {
    return request({
        url: '/delivery/pdd/batchAddPddProduct',
        method: 'post',
        data
    })
}
//获取拼多多商品
export function listProducts(data) {
    return request({
        url: '/delivery/pdd/listProducts',
        method: 'post',
        data
    })
}

//获取拼多多批货记录
export function productDeliveryLog(data) {
    return request({
        url: '/productDeliveryLog/page',
        method: 'post',
        data
    })
}

//发布oss图片到拼多多空间
export function uploadOssImage(data) {
    return request({
        url: '/delivery/pdd/uploadOssImage',
        method: 'post',
        data
    })
}
//拼多多素材编辑-图片上传
export function createMaterial(data) {
    return request({
        url: '/delivery/pdd/createMaterial',
        method: 'post',
        data
    })
}
//拼多多素材编辑-列表查询
export function listMaterial(data) {
    return request({
        url: '/delivery/pdd/listMaterial',
        method: 'post',
        data
    })
}

//拼多多素材编辑-删除
export function deleteMaterial(data) {
    return request({
        url: '/delivery/pdd/deleteMaterial',
        method: 'post',
        data
    })
}
//拼多多获取商品详情
export function getDetail(data) {
    return request({
        url: '/delivery/pdd/getDetail',
        method: 'post',
        data
    })
}

//5.商品编辑
export function updateProduct(data) {
    return request({
        url: '/delivery/pdd/updateProduct',
        method: 'post',
        data
    })
}
//6.单商品库存修改
export function singleUpdateStorage(data) {
    return request({
        url: '/delivery/pdd/singleUpdateStorage',
        method: 'post',
        data
    })
}

//7.单商品价格修改
export function singleUpdatePrice(data) {
    return request({
        url: '/delivery/pdd/singleUpdatePrice',
        method: 'post',
        data
    })
}
//4.草稿列表
export function pageCommits(data) {
    return request({
        url: '/delivery/pdd/pageCommits',
        method: 'post',
        data
    })
}
//删除草稿
export function dropDraft(data) {
    return request({
        url: '/delivery/pdd/dropDraft',
        method: 'post',
        data
    })
}

//4.发布的时候保存草稿
export function saveDraftWhenCommit(data) {
    return request({
        url: '/delivery/pdd/saveDraftWhenCommit',
        method: 'post',
        data
    })
}
//2.编辑的时候保存草稿
export function saveDraftWhenUpdate(data) {
    return request({
        url: '/delivery/pdd/saveDraftWhenUpdate',
        method: 'post',
        data
    })
}

//获取草稿详情
export function getDraftDetail(data) {
    return request({
        url: '/delivery/pdd/getDraftDetail',
        method: 'post',
        data
    })
}


//保存对草稿的修改
export function commitDraft(data) {
    return request({
        url: '/delivery/pdd/commitDraft',
        method: 'post',
        data
    })
}
//获取签名
export function getIsvPageCode(data) {
    return request({
        url: '/delivery/pdd/getIsvPageCode',
        method: 'post',
        data
    })
}