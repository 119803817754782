import { getOperateHis } from "@/api/operateLog";

const actions = {
  getOperateHis({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      getOperateHis(form, state.token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  actions,
  namespaced: true,
};
