import { getStorageList, relateGoods, saveStorage } from "@/api/goodsRel";
const actions = {
  getStorageList({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      getStorageList(form, state.token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  relateGoods({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      relateGoods(form, state.token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveStorage({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      saveStorage(form, state.token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
