import { queryGoodsScore, saveGoodsScore, deleteScore } from "@/api/goodsScore";
const actions = {
  queryGoodsScore({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      queryGoodsScore(form, state.token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveGoodsScore({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      saveGoodsScore(form, state.token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteScore({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      deleteScore(form, state.token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
