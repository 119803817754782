import request from "@/utils/request";

// 查询分组列表
export function listAuthorRecords(data) {
    return request({
        url: '/authorizeRel/listAuthorRecords',
        method: 'post',
        data
    })
}
//增加屏蔽厂家
export function addShieldSupplier(data) {
    return request({
        url: '/authorizeRel/addShieldSupplier',
        method: 'post',
        data
    })
}
//授权通过&取消&忽略
export function agreeAuthority(data) {
    return request({
        url: '/authorizeRel/agreeAuthority',
        method: 'post',
        data
    })
}
//申请授权
export function applyAuthority(data) {
    return request({
        url: '/authorizeRel/applyAuthority',
        method: 'post',
        data
    })
}
//删除 授权厂家&商家
export function deleteAuthority(data) {
    return request({
        url: '/authorizeRel/deleteAuthority',
        method: 'post',
        data
    })
}

//展示厂家图片
export function listAuthorSuppliersImages(data) {
    return request({
        url: '/authorizeRel/listAuthorSuppliersImages',
        method: 'post',
        data
    })
}
//屏蔽厂家
export function shieldSupplier(data) {
    return request({
        url: '/authorizeRel/shieldSupplier',
        method: 'post',
        data
    })
}
//屏蔽厂家
export function updateAuthorityRecord(data) {
    return request({
        url: '/authorizeRel/updateAuthorityRecord',
        method: 'post',
        data
    })
}
