import request from "@/utils/request";

// 推送商品
export function pushImage(data) {
    return request({
        url: '/pushImageInfo/pushImage',
        method: 'post',
        data
    })
}
//推送查看推送详情
export function getPushDetail(data) {
    return request({
        url: '/pushImageInfo/getPushDetail',
        method: 'post',
        data
    })
}


//商品接收
export function receiveImage(data) {
    return request({
        url: '/pushImageInfo/receiveImage',
        method: 'post',
        data
    })
}
//商品忽略
export function ignoreImage(data) {
    return request({
        url: '/pushImageInfo/ignoreImage',
        method: 'post',
        data
    })
}
//查看接收详情
export function getReceiveDetail(data) {
    return request({
        url: '/pushImageInfo/getReceiveDetail',
        method: 'post',
        data
    })
}

//获取厂商电话号码
export function checkImage(data) {
    return request({
        url: '/pushImageInfo/checkImage',
        method: 'post',
        data
    })
}
