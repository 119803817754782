import request from "@/utils/request";


export function addToImageRepository(data) {
    return request({
        url: '/supplierImage/addToImageRepository',
        method: 'post',
        data
    })
}
// 上传图片到oss
export function getDetailInfo(data) {
    return request({
        url: '/supplierImage/getDetailInfo',
        method: 'post',
        data
    })
}
// 上传图片到oss
export function getPushDetailInfo(data) {
    return request({
        url: '/supplierImage/getPushDetailInfo',
        method: 'post',
        data
    })
}

//获取图片列表
export function getImageList(data) {
    return request({
        url: '/supplierImage/getImageList',
        method: 'post',
        data
    })
}
//添加商品
export function uploadImage(data) {
    return request({
        url: '/supplierImage/uploadImage',
        method: 'post',
        data
    })
}
//更改图片类型
export function updateImageType(data) {
    return request({
        url: '/supplierImage/updateImageType',
        method: 'post',
        data
    })
}
//更改图片类型
export function insertDownloadHis(data) {
    return request({
        url: '/supplierImage/insertDownloadHis',
        method: 'post',
        data
    })
}
