import request from "@/utils/request";

export function getCancelRate(data) {
  return request({
    url: "/cancel/getCancelRate",
    method: "post",
    data,
  });
}

export function getCancelType(data) {
  return request({
    url: "/cancel/getCancelType",
    method: "post",
    data,
  });
}
