import request from "@/utils/request";

export function getBaseInfo(data) {
  return request({
    url: "/remark/getBaseInfo",
    method: "post",
    data,
  });
}

export function IsSupplierAuthored(data) {
  return request({
    url: "/remark/IsSupplierAuthored",
    method: "post",
    data,
  });
}

export function saveRemarkInfo(data) {
  return request({
    url: "/remark/saveRemarkInfo",
    method: "post",
    data,
  });
}
