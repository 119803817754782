import { listGroup,addToGroup,deleteGroup,saveAuthorGroup } from '@/api/authorizeGroupRel'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    saveAuthorGroup({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            saveAuthorGroup(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //归纳至分组
    deleteGroup({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            deleteGroup(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //归纳至分组
    addToGroup({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            addToGroup(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 查询分组列表
    listGroup({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            listGroup(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions
}
